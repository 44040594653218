import { useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ModuleList from"./components/ModuleList" 
import Module from"./components/Module" 

import './App.css';

function App() {
  const [progress, setProgress] = useState([])

  useEffect(() => {
    try {
      const value = localStorage.getItem('module-progress')
      const decodedValue = value ? JSON.parse(value) : []
      setProgress(decodedValue)
    } catch(e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    try {
      if(progress.length) {
        const value = JSON.stringify(progress)
        localStorage.setItem('module-progress', value)
      }
    } catch(e) {
      console.error(e)
    }
  }, [progress])

  return (
    <Router>
      <div className="web-app">
        <Routes>
          <Route exact path="/" element={<ModuleList progress={progress} setProgress={setProgress} />} />
          <Route exact path="/module/:moduleId" element={<Module progress={progress} setProgress={setProgress} />} />
          <Route exact path="/module/:moduleId/document/:lessonId" element={<Module progress={progress} setProgress={setProgress} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
