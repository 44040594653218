import { Link, matchRoutes, useParams } from "react-router-dom"

import modules from "../data/modules.json"
import './Module.css';

const Module = ({ progress, setProgress }) => {
    const params = useParams()
    const moduleId = params.moduleId 

    const module = modules.find(m => m.id === moduleId)

    const lessonId = module && params.lessonId || module?.lessons[0]?.id

    const lesson = module?.lessons.find(l => l.id === lessonId)

    const addProgress = (moduleId, lessonId) => {
        setProgress((progress) => [
            ...progress.filter(p => p !== `${moduleId}--${lessonId}`),
            `${moduleId}--${lessonId}`
        ])
    }

    return (
        <div className="module-view">
            { module ? (
                <>
                    <p className="breadcrumb">
                        <Link to="/">
                            Integration en Allemagne
                        </Link>
                        {" / "}
                        <span>{module.name}</span>
                    </p>
                    <div className="media-container">
                        <div className="media-frame">
                            {lesson.type === "pdf" ? (
                                <div className="pdf-container">
                                    <object type="application/pdf" data={lesson.file_path} style={{ width: "100%", height: 600 }} >
                                        <iframe style={{ width: "100%", height: 600 }} src={lesson.file_path} />
                                    </object>
                                </div>
                            ) : lesson.type === "audio" ? (
                                <div className="audio-container">
                                    <audio style={{ width: "100%", height: 300 }} controls src={lesson.file_path} />
                                </div>
                            ) : lesson.type === "video" && (
                                <div className="video-container">
                                    <video src={lesson.file_path} controls muted={false} />
                                </div>
                            )}
                        </div>
                        <div className="media-list"> 
                            <div className="header">
                                <h2>{module.name}</h2>
                                <p className="number-of-lessons">
                                    {module.lessons.length} documents
                                </p>
                            </div>
                            <ul className="media-list-items">
                                {module.lessons.map((lesson, i) => (
                                    <li className={lesson.id === lessonId ? "active" : ""} key={lesson.id}>
                                        <Link to={`/module/${module.id}/document/${lesson.id}`}>
                                            <span className="lesson-number">{i + 1}.</span>
                                            <span className="lesson-name">{lesson.name}</span>
                                            <span className="lesson-completed">
                                                {progress.includes(`${module.id}--${lesson.id}`) && (
                                                    <span className="check" />
                                                )}
                                            </span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="mark-as-completed">
                        <button onClick={() => addProgress(module.id, lesson.id)}>Marquer comme terminé</button>
                    </div>
                </>
            ) : (
                <>
                    <h1>Module Not found</h1>
                </>
            ) }
        </div>
    )
}

export default Module