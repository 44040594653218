import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import modules from "../data/modules.json"
import './ModuleList.css';

const LOCAL_STORAGE_ENABLE_MODULES_KEY = 'enable-modules'

const ModuleList = ({ progress }) => {
    const [searchParams] = useSearchParams();
    const [enableModules, setEnableModules] = useState([])

    useEffect(() => {
        const modulesToEnable = []
        
        try {
            const currentlyEnabledModuleString = localStorage.getItem(LOCAL_STORAGE_ENABLE_MODULES_KEY)
            if(currentlyEnabledModuleString) {
                const currentlyEnabledModules = currentlyEnabledModuleString.split(",")
                for(const currentlyEnabledModule of currentlyEnabledModules) {
                    if(!modulesToEnable.includes(currentlyEnabledModule)) {
                        modulesToEnable.push(currentlyEnabledModule)
                    }
                }
            }
        } catch(e) {
            console.error(e)
        }

        if(searchParams.get("enable-modules")) {
            for(const moduleToEnable of searchParams.get("enable-modules").split(",")) {
                if(!modulesToEnable.includes(moduleToEnable)) {
                    modulesToEnable.push(moduleToEnable)
                }
            }

            try {
                localStorage.setItem(LOCAL_STORAGE_ENABLE_MODULES_KEY, modulesToEnable.join(","))
            } catch(e) {
                console.error(e)
            }
        }

        setEnableModules(modulesToEnable)
    }, [])

    return (
        <div className="container">
            <div className="module-list">
                <h2>Mes modules</h2>
                {modules.filter(module => enableModules.includes(module.id)).map((module) => { 
                    const progressPercentage = 100 * progress.filter(p => p.split("--")[0] === module.id).length / module.lessons.length

                    return (
                        <div className="module" key={module.id}>
                            <div className="image">
                                <Link to={`/module/${module.id}`} >
                                    <img src={module.image} />
                                </Link>
                            </div>
                            <div className="content">
                                <h3>
                                    <Link to={`/module/${module.id}`} >
                                        {module.name}
                                    </Link>
                                </h3>
                                {progressPercentage === 100 ? (
                                    <div className="tick-mark-container">
                                        <div className="tick-mark" />
                                    </div>
                                ) : (
                                    <div className="progress-bar">
                                        <div className="progress" style={{ width: `${progressPercentage}%` }} />
                                    </div>
                                )}
                                <p>
                                    {module.description}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ModuleList